import {Component, Input, OnInit} from '@angular/core';
import {COOKIE_CONTENT} from '../../utils/constans';
import {ModalController} from "@ionic/angular";
import {AppCookieService} from "../../services/cookie.service";
import {SessionStorage} from "../../services/session-storage";

@Component({
  selector: 'app-cookies-modal',
  templateUrl: './cookies-modal.component.html',
  styleUrls: ['./cookies-modal.component.scss'],
})
export class CookiesModalComponent implements OnInit {
  @Input() type: string | 'Cookies' | 'CookiesAccepted' = 'Cookies';
  @Input() job: any;

  cookieName: string = 'cookie';
  cookieNames: any;
  trackingPixelConfig: any = null
  trackingPixelConfigData: any = null;
  cookieActivated: boolean = false;

  source: string;
  cookieContent = COOKIE_CONTENT;


  constructor(
    private modalController: ModalController,
    public cookieService: AppCookieService,
    private sessionStorage: SessionStorage,
  ) {}

  ngOnInit() {
    this.source = this.sessionStorage.getItem('source');
    this.trackingPixelConfigData = this.trackingPixelConfig = JSON.parse(this.job?.tracking_pixel_config)?.filter(trackingPixel =>
      trackingPixel?.id && trackingPixel?.entry_points.includes(this.source)).map(data => {
      return {
        ...data,
        showInformation: false,
        cookieContent: this.cookieContent.find(cookieContent => cookieContent.key === data.type),
      }
    });
    this.cookieNames = [];
    this.trackingPixelConfigData.forEach(config => {
      this.cookieNames.push('cookie_' + config?.type + '_' + config?.id);
    });

    this.type = this.cookieNames.every(name => this.cookieService.hasConsent(name)) ? 'CookiesAccepted' : 'Cookies';
    this.cookieActivated = this.cookieNames.every(name => !!this.cookieService.consentEnabled(name));

    if(this.trackingPixelConfigData.some( config => config.type === 'gtm_pixel_id')){
      if(!this.trackingPixelConfigData.some( config => config.type === 'instagram_pixel_id')) {
        this.trackingPixelConfigData.push({
          cookieContent: this.cookieContent.find(cookieContent => cookieContent.key === 'instagram_pixel_id'),
          entry_points: ['social'],
          id: null,
          showInformation: false,
          type: "instagram_pixel_id"
        });
      }
      if(!this.trackingPixelConfigData.some( config => config.type === 'facebook_tracking_pixel_id')) {
        this.trackingPixelConfigData.push({
          cookieContent: this.cookieContent.find(cookieContent => cookieContent.key === 'facebook_tracking_pixel_id'),
          entry_points: ['social'],
          id: null,
          showInformation: false,
          type: "facebook_tracking_pixel_id"
        });
      }
      if(!this.trackingPixelConfigData.some( config => config.type === 'advertising_id')) {
        this.trackingPixelConfigData.push({
          cookieContent: this.cookieContent.find(cookieContent => cookieContent.key === 'advertising_id'),
          entry_points: ['social'],
          id: null,
          showInformation: false,
          type: "advertising_id"
        });
      }
      if(!this.trackingPixelConfigData.some( config => config.type === 'google_analytics_pixel_id')) {
        this.trackingPixelConfigData.push({
          cookieContent: this.cookieContent.find(cookieContent => cookieContent.key === 'google_analytics_pixel_id'),
          entry_points: ['social'],
          id: null,
          showInformation: false,
          type: "google_analytics_pixel_id"
        });
      }
      this.removeGTMFromList();
    }
  }


  allowCookie(): void {
    this.cookieNames.forEach(cookieName => this.cookieService.allowCookies(cookieName));
    this.injectCookies();
    this.modalController.dismiss();
  }

  discardCookie(): void {
    this.cookieNames.forEach(cookieName => this.cookieService.forbidCookies(cookieName));
    this.closeModal();
  }

  cookieSubmit(): void {
    if (this.cookieActivated) {
      this.allowCookie();
      return;
    }

    this.removeInjectedCookies();
    location.reload();
    this.closeModal();
  }

  injectCookies() {
    this.cookieNames.forEach(cookieName => this.cookieService.allowCookies(cookieName));

    this.trackingPixelConfig.forEach(config => {
      if(config.type && config.id){
        if (config.type === 'google_analytics_pixel_id') {
          // gtag for analytics
          this.cookieService.injectGooglePixel('gtag', config.id, config.type)
        }
        if (config.type === 'advertising_id') {
          // gtag for analytics
          this.cookieService.injectGooglePixel('gtag', config.id, config.type)
        }
        if (config.type === 'gtm_pixel_id') {
          // gtm for google tag manager
          this.cookieService.injectGooglePixel('gtm', config.id, config.type)
        }
        if (config.type === 'facebook_tracking_pixel_id') {
          this.cookieService.injectFacebookPixel(config.id, config.type, this.job?.utm_source)
        }
        if (config.type === 'instagram_pixel_id') {
          this.cookieService.injectFacebookPixel(config.id, config.type, this.job?.utm_source)
        }
      }
    });
  }

  removeInjectedCookies() {
    this.cookieNames.forEach(cookieName => {
      this.cookieService.forbidCookies(cookieName);
      this.cookieService.removeCookie(cookieName);
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  isLink(value) {
    return value.startsWith('http');
  }

  changeShowMore(item) {
    this.trackingPixelConfigData = this.trackingPixelConfigData.map(trackingPixel => (
      {
        ...trackingPixel,
        showInformation: item.type === 'gtm_pixel_id' ? !item.showInformation : item.id === trackingPixel.id && !item.showInformation
      }))
  }

  activateTrackingPixel(event) {
    this.cookieActivated = event?.target.checked
  }

  private removeGTMFromList() {
    const index = this.trackingPixelConfigData.findIndex(item => item.type === 'gtm_pixel_id');
    if(index > -1) {
      this.trackingPixelConfigData = [...this.trackingPixelConfigData.slice(0,index), ...this.trackingPixelConfigData.slice(index + 1)];
    }
  }
}
