// Angular
import {Pipe, PipeTransform} from '@angular/core';
import * as Hypher from 'hypher';
import * as german from 'hyphenation.de';
import * as english from 'hyphenation.en-gb';
import {TranslateService} from "@ngx-translate/core";


interface HyphenateOptions {
  onlyNLongest?: number;
  longerThan?: number;
}

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'hyphenate'
})
export class HyphenatePipe implements PipeTransform {

  /**
   * Pipe Constructor
   *
   * @param sanitizer: DomSanitezer
   */
  private deHyphenator: Hypher = null;
  private enHyphenator: Hypher = null;
  private hyphenChar = '\u00AD';
  // private hyphenChar = '*';
  private currentLanguage = null;

  constructor(private translate: TranslateService) {
    this.enHyphenator = new Hypher(english);
    this.deHyphenator = new Hypher(german);
  }

  /**
   * Transform
   *
   */
  transform(text: string, options: HyphenateOptions = {}): string {
    const words = text.split(/\s+/);
    const hyphenateNLongest = Math.min(
      words.length, options.onlyNLongest || words.length
    );
    const hyphenateLongerThan = options.longerThan || 0;

    const wordsToHyphenate = words
      .concat()
      .sort((word1, word2) => word2.length - word1.length)
      .slice(0, hyphenateNLongest)
      .filter(word => word.length > hyphenateLongerThan);
    
    let currentLanguage = 'de';
    if (!(this.translate.currentLang == '' || this.translate.currentLang == 'de-informal')) {
      currentLanguage = this.translate.currentLang;
    }

    return words
      .map(word => {
        if (wordsToHyphenate.indexOf(word) !== -1) {
          if(currentLanguage == 'de')
            return this.deHyphenator.hyphenate(word).join(this.hyphenChar);
          return this.enHyphenator.hyphenate(word).join(this.hyphenChar);
        }
        return word;
      })
      .join(' ');
  }
}
