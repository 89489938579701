import { Injectable } from '@angular/core';
import {ContactDetailsComponent} from "../modals/contact-details/contact-details.component";
import {GdprModalComponent} from "../modals/gdpr-modal/gdpr-modal.component";
import {EditQuestionComponent} from "../modals/edit-question/edit-question.component";
import {ModalController} from "@ionic/angular";
import {BehaviorSubject, Observable} from "rxjs";
import {CookiesModalComponent} from "../modals/cookies-modal/cookies-modal.component";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  private _scroll: BehaviorSubject<any> = new BehaviorSubject(0);
  readonly scroll$: Observable<any> = this._scroll.asObservable();

  constructor(protected modalController: ModalController,private cookieService: CookieService) { }

  setScroll(scroll){
    this._scroll.next(scroll);
  }

  async gdprModal(GDPRImpr) {
    const modal = await this.modalController.create({
      component: GdprModalComponent,
      cssClass: 'gdpr-modal custom-modal',
      componentProps: {text: GDPRImpr},
      initialBreakpoint: 0.78
    });
    return await modal.present();
  }

  async cookiesModal(type, job?) {
    const modal = await this.modalController.create({
      component: CookiesModalComponent,
      cssClass: 'gdpr-modal custom-modal',
      componentProps: {type, job},
      initialBreakpoint: 0.78
    })
    return await modal.present();
  }
}
