import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AbstractService} from './abstract.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from "@angular/router";
import {ContactDetailsComponent} from "../modals/contact-details/contact-details.component";
import {ModalController, ToastController} from "@ionic/angular";
import {EditQuestionComponent} from "../modals/edit-question/edit-question.component";
import {TranslateService} from "@ngx-translate/core";
import {Util} from "../utils/Util";
import {SessionStorage} from "./session-storage";
import {AppCookieService} from "./cookie.service";
import { FeedbackComponent } from '../../@LogOn/modals/feedback/feedback.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ExecutorService extends AbstractService {

  private _candidate: BehaviorSubject<any> = new BehaviorSubject(null);
  readonly candidate$: Observable<any> = this._candidate.asObservable();

  private _job: BehaviorSubject<any> = new BehaviorSubject(null);
  readonly job$: Observable<any> = this._job.asObservable();

  private _showCookieModal: BehaviorSubject<any> = new BehaviorSubject(false);
  readonly showCookieModal$: Observable<any> = this._showCookieModal.asObservable();


  private _progressClicked: BehaviorSubject<any> = new BehaviorSubject(false);
  readonly progressClicked: Observable<any> = this._progressClicked.asObservable();

  readonly languages$: Observable<any[]> = this.job$.pipe(
    map(job => {
      return job?.languages?.map(item => item.language_code) ?? [];
    })
  );

  readonly canApply$: Observable<boolean> = this.candidate$.pipe(
    map(candidate => {
      return this.canApply(candidate);
    })
  );

  readonly percentage$: Observable<number> = this.candidate$.pipe(
    map(candidate => {
      if (!candidate || !candidate.job)
        return 0;
      if (this.canApply(candidate))
        return 100;
      let percentage = 0;
      if (candidate.job_info_validation_feedback && candidate.job_info_validation_feedback != 'default') {
        percentage += candidate.job.job_info_progress;
      }
      if (candidate.upload_validation_feedback && candidate.upload_validation_feedback != 'default') {
        percentage += candidate.job.upload_progress;
      }
      if (candidate.contact_validation_feedback && candidate.contact_validation_feedback != 'default') {
        percentage += candidate.job.contact_progress || 0;
      }
      const sumQuestionProgress = candidate.answers?.reduce((acc, answer) => acc + answer.question.progress, 0) ?? 0;
      percentage += Math.round(candidate.job.chat_progress / 100 * sumQuestionProgress);

      return percentage > 100 ? 100 : percentage;
    })
  );

  readonly feedbackExists$: Observable<boolean> = this.candidate$.pipe(
    map(candidate => {
      return candidate?.uploads?.filter(upload => upload?.type === "CV")?.length > 0;
    })
  );

  constructor(protected http: HttpClient,
              protected router: Router,
              protected route: ActivatedRoute,
              protected translate: TranslateService,
              protected sessionStorage: SessionStorage,
              protected modalController: ModalController,
              private cookieService: AppCookieService,
              private spinner: NgxSpinnerService,
              public toastController: ToastController) {
    super(http);
    this.candidate$.subscribe(async (candidate: any) => {
      if (candidate == null)
        return;

      let tips = candidate?.tips || [];
      const tipsToRemove = [];
      const tipsToAdd = [];

      for (const tip of tips) {
        switch (tip.action) {
          case "SYSTEM_START_CHAT":
            if (candidate.answers?.length > 0) {
              tipsToRemove.push(tip.id);
              tipsToAdd.push({
                type: 'HAVE_TO',
                action: 'ANSWER_REQUIRED_QUESTIONS',
                text: 'Answer all required answers',
                section: 'CHAT'
              });
            }
            break;
          case "SYSTEM_ADD_CONTACT_DETAILS":
          case "SYSTEM_OPEN_CONTACT_FORM":
            if (candidate.phone || candidate.email) {
              tipsToRemove.push(tip.id);
            }
            break;
          case "SYSTEM_OPEN_JOB_INFO":
            if (candidate.job_info_validation_feedback) {
              tipsToRemove.push(tip.id);
            }
            break;

          case "SYSTEM_UPLOAD":
            if (candidate.uploads?.length > 0) {
              tipsToRemove.push(tip.id);
            }
            break;
          case "ANSWER_REQUIRED_QUESTIONS":
            const restartChat = candidate.answers.find(answer => answer?.tip?.action == 'RESTART_CHAT');

            const requiredQuestionIds = this.getQuestions().filter(question => question.required == true).map(item => item.id);
            const answeredQuestionIds = (candidate?.answers || []).map(item => item.job_language_question_id);

            if (!!restartChat || requiredQuestionIds.every(item => answeredQuestionIds.includes(item))) {
              tipsToRemove.push(tip.id);
            }
            break;
        }
      }

      if (tipsToAdd?.length == 0 && tipsToRemove?.length == 0)
        return;

      await this.syncTips(tipsToAdd, tipsToRemove);
    });
  }

  onProgressClicked(clicked: boolean) {
    this._progressClicked.next(clicked);
  }

  getJob(source, company, slug) {
    return this.get(`/just-apply/sources/${source}/companies/${company}/jobs/${slug}`)
      .pipe(tap(job => {
        const trackingPixels = JSON.parse(job.tracking_pixel_config)
        if (trackingPixels?.some(config => config.entry_points?.includes(source))) {
          this._showCookieModal.next(true);
          this.cookieService.initialInjectForAllowedCookies(job, source);
        } else {
           this._showCookieModal.next(false);
        }
        this.setJob(this.prepareJob(job))
      }));
  }

  prepareJob(job) {
    for (let index in job.languages) {
      job.languages[index].core = job.core_json.jap.find(item => item.language == job.languages[index].language_code);
    }
    return job;
  }

  createCandidate(job, languageCode = 'de', entryPoint = 'social') {
    const queryParams = this.route.snapshot.queryParams

    const payload = {
    language_code: languageCode,
      utm_source: queryParams?.cmpId,
      utm_medium: queryParams?.utm_medium,
      utm_campaign: queryParams?.utm_campaign,
      entry_point: entryPoint,
    }

    this.sessionStorage.removeItem('candidate');
    this._candidate.next(null);
    return this.post(`/just-apply/jobs/${job.id}/candidates`, payload)
      .pipe(tap((data: any) => {
        this._candidate.next({...data, job: job});
        this.setAppLanguage(languageCode);
        this.sessionStorage.setItem('candidate', JSON.stringify({
          candidate_id: this._candidate.value.id,
          job_id: this._candidate.value.job.id,
          uuid: this._candidate.value.uuid
        }));
      }));
  }

  retrieveCandidate() {
    const data = this.getStorageCandidateData();

    return this.get(`/just-apply/jobs/${data.job_id}/candidates/${data.candidate_id}`)
      .pipe(tap((candidate: any) => {
        this.setAppLanguage(candidate.language_code);
        const job = this.prepareJob(candidate.job);
        const source = this.sessionStorage.getItem('source')
        const trackingPixels = JSON.parse(job.tracking_pixel_config)

        if (trackingPixels?.some(config => config.entry_points?.includes(source))) {
          this._showCookieModal.next(true);
          this.cookieService.initialInjectForAllowedCookies(job, source);
        } else {
           this._showCookieModal.next(false);
        }

        this.setJob(job);
        this.updateCandidateObject({...candidate, job: job});
      }));
  }

  setJob(job) {
    this._job.next(job);
  }

  getCandidate(): any {
    return this._candidate.value;
  }

  getStorageCandidateData() {
    return JSON.parse(this.sessionStorage.getItem('candidate'));
  }

  getQuestions(): any[] {
    return this.getCandidate().job.languages.find(item => item.language_code == this.getLanguage())?.questions || [];
  }

  answer(id, answer: string, answerObject = null) {
    const candidate = this.getCandidate();
    return this.post(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/questions/${id}/answers`, {
      answer: answer
    }).pipe(
      tap((data: any) => {
        if (!candidate.answers)
          candidate.answers = [];
        candidate.answers.push(data);
        this.answerAction(candidate, data, answer, answerObject);
      }));
  }

  editAnswer(id, answer: string, answerId, answerObject) {
    const candidate = this.getCandidate();
    return this.put(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/questions/${id}/answers/${answerId}`, {
      answer: answer
    }).pipe(
      tap((newAnswer: any) => {
        const candidate = this.getCandidate();
        const index = candidate.answers.findIndex(answer => answer.id == answerId);

        candidate.answers[index] = newAnswer;
        this.answerAction(candidate, newAnswer, answer, answerObject);
      })
    );
  }

  getLanguage() {
    return this._candidate.value.language_code;
  }

  getLanguageIndex() {
    return this._candidate.value.job.languages.findIndex(l => l.language_code == this.getLanguage());
  }

  changeLanguage(code) {
    return this.restartChat(code);
  }

  setContactData(payload) {
    const candidate = this.getCandidate();
    return this.put(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/contact-details`, payload).pipe(
      tap((data: any) => {
        this.syncTips();
        this.updateCandidateObject(data);
      }));
  }

  private updateCandidateObject(data) {
    this._candidate.next({...this._candidate.value, ...data});
  }

  getCandidateCredentials() {
    return JSON.parse(this.sessionStorage.getItem('candidate'));
  }

  removeCandidateCredentials() {
    this.sessionStorage.removeItem('candidate');
  }

  candidateUpload(type, file: File) {
    const candidate = this.getCandidate();
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', type);


    return this.post(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/uploads`, formData)
      .pipe(tap((data: any) => {
        const candidate = this.getCandidate();

        if (!candidate.uploads)
          candidate.uploads = [];
        candidate.upload_validation_feedback = data.validation_feedback;

        candidate.uploads.push(data);
        this._candidate.next(candidate);
        this.syncTips();
      }));
  }

  deleteCandidateUpload(id) {
    const candidate = this.getCandidate();
    return this.delete(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/uploads/${id}`)
      .pipe(tap((data: any) => {
        const candidate = this.getCandidate();
        candidate.upload_validation_feedback = data.validation_feedback;
        candidate.uploads = candidate.uploads.filter(item => item.id != id);
        this._candidate.next(candidate);
        this.syncTips();
      }));
  }


  action(type) {
    switch (type) {
      case 'SYSTEM_START_CHAT':
      case'ANSWER_REQUIRED_QUESTIONS':
        this.router.navigate(['/tabs/chat']);
        break;
      case'SYSTEM_OPEN_JOB_INFO':
        this.router.navigate(['/tabs/job-info']);
        break;
      case 'SYSTEM_UPLOAD':
      case 'UPLOAD':
        if (this.router.url.includes("simple-jap")) {
          this.router.navigate(['/simple-jap/upload']);
        } else {
          this.router.navigate(['/tabs/upload']);
        }
        break;
      case 'SYSTEM_OPEN_CONTACT_FORM':
      case 'OPEN_CONTACT_FORM':
        this.contactModal();
        break;
      case 'RESTART_CHAT':
        this.restartChat().toPromise().then(data => this.router.navigate(['/tabs/chat']));
        break;
    }

    if (type.startsWith('OPEN_CHAT')) {
      this.editAnswerModal(type);
    }

  }

  async syncTips(tipsToAdd: any[] = [], tipsToRemove: any[] = []) {
    const candidate = this.getCandidate();
    return await this.put(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/tips`, {
      add: tipsToAdd,
      remove: tipsToRemove
    }).pipe(tap((newTips: any) => this.updateCandidateObject({tips: newTips})))
      .toPromise();
  }

  update(data) {
    const candidate = this.getCandidate();
    return this.put(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}`, data).pipe(
      tap((data: any) => this.updateCandidateObject(data))
    );
  }

  apply(rawValue?: any) {
    const candidate = this.getCandidate();
    const overallFeedback = Util.calculateOverAllFeedback(candidate, this.getQuestions().length);
    return this.post(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/apply`, {
      full_name: candidate.full_name,
      email: candidate.email,
      phone: candidate.phone,
      phone_obj: candidate.phone_obj,
      address: candidate.address,
      ...rawValue,
      overall_feedback: overallFeedback
    })
      .pipe(tap(data => this.sessionStorage.removeItem('candidate')));
  }

  async contactModal() {
    const modal = await this.modalController.create({
      component: ContactDetailsComponent,
      cssClass: 'custom-modal',
      initialBreakpoint: 0.7
    });
    return await modal.present();
  }

  async editAnswerModal(type) {
    const modal = await this.modalController.create({
      component: EditQuestionComponent,
      componentProps: {
        no: type.split(':')[1]
      },
      cssClass: 'custom-modal edit-question-modal'
    });
    return await modal.present();
  }

  openFeedbackIfMainSkillAvailable() {
    this.getFeedback().subscribe(res => {
      let feedbackData = res;
      let mainSkillCV = feedbackData.match.professional_group.main_skill_cv;
      if (mainSkillCV.value && mainSkillCV.term && mainSkillCV.color) {
        this.spinner.hide();
        this.openFeedback(feedbackData);
      } else {
        this.spinner.hide();
        this.openNoFeedbackToast();
      }
    })
  }

  async openNoFeedbackToast() {
    const toast = await this.toastController.create({
      message: this.translate.instant("app_feedback_could_not_be_identified"),
      duration: 6000,
      cssClass: 'required-question'
    });
    toast.present();
  }

  async openFeedback(feedbackData) {
    const modal = await this.modalController.create({
      component: FeedbackComponent,
      cssClass: 'feedback-modal modal-wrapper',
      initialBreakpoint: 0.9,
      componentProps: { 
        feedbackData: feedbackData
      },
    });
    await modal.present();
  }

  canApply(candidate = null) {
    if (candidate == null)
      candidate = this.getCandidate();
    return (candidate?.tips?.filter(tip => tip.type == 'HAVE_TO')?.length || 0) === 0
  }

  getPhoneObjFromPhoneNumber(phoneNumber: string) {
    /* let x;
    let nationalNumber;
    let countryCode;

    if (phoneNumber.startsWith("00")) {
        x = "+" + phoneNumber.substring(2);
        countryCode = x.slice(0,3);
        nationalNumber = x.substring(3);
    } else if (phoneNumber.startsWith("+")) {
        x = phoneNumber;
        countryCode = x.slice(0,3);
        nationalNumber = x.substring(3);
    } else {
        nationalNumber = phoneNumber;
        countryCode = "+49";
    } */
    return {
      internationalNumber: phoneNumber,
      nationalNumber: phoneNumber,
      isoCode: "de"
    }
  }

  mergeNumericStringsInArray(data) {
    let i = 0;
    let mergedArray= [];
    while (i < data.length) {
      let currentString = data[i];

      // Check if the current string is numeric
      if (!isNaN(Number(currentString))) {
        let mergedString = currentString;

        // Continue merging with the next numeric strings
        while (i + 1 < data.length && !isNaN(Number(data[i + 1]))) {
          mergedString += data[i + 1];
          i++;
        }

        // Push the merged numeric string to the result array
        mergedArray.push(mergedString);
      } else {
        // If the current string is not numeric, push it as is
        mergedArray.push(currentString);
      }

      i++;
    }
    return mergedArray;
  }
  private answerAction(candidate: any, data: any, answer, answerObject) {
    if (data.validation_action) {
      switch (data.validation_action) {
        case 'FILL_NAME':
          candidate.full_name = answer;
          this.setContactData({full_name: answer}).toPromise().then();
          break;
        case 'FILL_EMAIL':
          if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(answer)) {
            candidate.email = answer;
            this.setContactData({email: answer}).toPromise().then();
          }
          break;
        case 'FILL_PHONE':
          if (/^[0-9+\-()\s.]+$/.test(answer)) {
            candidate.phone = answer;
            if (!answerObject) {
              answerObject = this.getPhoneObjFromPhoneNumber(answer);
            } else {
              candidate.phone_obj = answerObject;
            }
            this.setContactData({phone: answer, phone_obj: answerObject}).toPromise().then();
          }
          break;
        case 'FILL_CONTACT':
          const pattern = /[;,]+/;
          const tempAnswResult = this.mergeNumericStringsInArray(answer.split(pattern));
          tempAnswResult.forEach(element => {
            if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(element)) {
              if (!candidate.email){
                candidate.email = element;
                }
              }else if (/^[0-9+\-()\s.]+$/.test(element)) {
                  if(!candidate.phone_obj)
                  {
                    candidate.phone = element;
                    answerObject = this.getPhoneObjFromPhoneNumber(element);
                    candidate.phone_obj = answerObject;
                  }  
              }
              else {
                if (!candidate.full_name) {
                  candidate.full_name = element;
                }
              }
          });
          this.setContactData({email: candidate.email, phone: candidate.phone, phone_obj: candidate.phone_obj,full_name:candidate.full_name}).toPromise().then();
          break;
        case 'FILL_ADDRESS':
          candidate.address = answer;
          this.setContactData({address: answer}).toPromise().then();
          break;
        case 'FILL_NAME_EMAIL':
          if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(answer.split(";")[1])) {
            candidate.full_name = answer.split(";")[0];
            candidate.email = answer.split(";")[1];
            this.setContactData({
              full_name: candidate.full_name,
              email: candidate.email
            }).toPromise().then();
          }
          break;
        case 'FILL_NAME_PHONE':
          if (/^[0-9+\-()\s.]+$/.test(answer.split(";")[1])) {
            candidate.full_name = answer.split(";")[0];
            candidate.phone = answer.split(";")[1];
            candidate.phone_obj = answerObject;
            this.setContactData({
              full_name: candidate.full_name,
              phone: candidate.phone,
              phone_obj: candidate.phone_obj
            }).toPromise().then();
          }
          break;
        case 'FILL_CONTACT_ALL':
          if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(answer.split(";")[1]) ||
              /^[0-9+\-()\s.]+$/.test(answer.split(";")[2])) {
            candidate.full_name = answer.split(";")[0];
            candidate.email = answer.split(";")[1];
            candidate.phone = answer.split(";")[2];
            candidate.phone_obj = answerObject;
            this.setContactData({
              full_name: candidate.full_name,
              email: candidate.email,
              phone: candidate.phone,
              phone_obj: candidate.phone_obj
            }).toPromise().then();
          }
          break;
      }
    }
    this.updateCandidateObject(candidate);
    if (data.validation_action && ['FILL_NAME', 'FILL_EMAIL', 'FILL_PHONE', 'FILL_ADDRESS', 'FILL_NAME_EMAIL', 'FILL_NAME_PHONE', 'FILL_CONTACT_ALL','FILL_CONTACT'].indexOf(data.validation_action) == -1)
      this.action(data.validation_action);
  }

  restartChat(languageCode = null) {
    const candidate = this.getCandidate();
    return this.post(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/restart-chat`, {
      language_code: languageCode
    }).pipe(tap((data: any) => {
      this.updateCandidateObject({answers: [], language_code: data.language_code, full_name: data.full_name, email: data.email, phone: data.phone})
      this.syncTips();
      if (languageCode)
        this.setAppLanguage(languageCode);
    }));
  }

  setAppLanguage(languageCode) {
    this.translate.use(languageCode);
  }

  getFeedback() {
    const candidate = this.getCandidate();
    return this.get(`/just-apply/jobs/${candidate.job_id}/candidates/${candidate.id}/feedback`);
  }

  getNewFeedback(): Observable<any> {
    return this.http.get('../../../assets/feedback-dummy/dummy_feedback.json')
  }

  getCurrentQuestion(){
    const candidate = this.getCandidate();
    const questionNo = candidate.answers?.length > 0 ? candidate.answers[candidate?.answers?.length - 1]?.go_to : 1;

    const questions = candidate.job.languages.find(lang => lang.language_code === candidate.language_code)?.questions || [];
    return questions.find(item => item.no == questionNo);
  }
}
