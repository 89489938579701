import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {TabsResolver} from "./tabs/tabs.resolver";
import {SimpleJapResolver} from "./simple-jap/simple-jap.resolver";
import { SimpleJapComponent } from './simple-jap/simple-jap.component';

const routes: Routes = [
  {
    path: ':source/:company/:slug',
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'welcome-bmw',
    loadChildren: () =>
      import('./welcome/welcome.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'tabs',
    resolve: [TabsResolver],
    loadChildren: () =>
      import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'getting-started',
    loadChildren: () =>
      import('./getting-started/getting-started.module').then(
        (m) => m.GettingStartedPageModule
      ),
  },
  {
    path: 'applied-success',
    loadChildren: () => import('./applied-success/applied-success.module').then(m => m.AppliedSuccessPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then(m => m.TestPageModule)
  },
  {
    path: 'simple-jap',
    component: SimpleJapComponent,
    resolve: [SimpleJapResolver],
    loadChildren: () =>
      import('./simple-jap/simple-jap.module').then((m) => m.SimpleJapModule),
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled'}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
