import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {ExecutorService} from "../@LogOn/services/executor.service";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SimpleJapResolver implements Resolve<any> {

  constructor(private executor: ExecutorService, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (this.executor.getCandidate()) {
      return true;
    }

    if (!this.executor.getStorageCandidateData())
      return this.router.navigate(['/']);

    return this.executor.retrieveCandidate()
      .pipe(
        catchError(error => {
          this.router.navigate(['/']);
          return throwError(error);
        })
      );
  }
}
