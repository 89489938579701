

export class Util {
  static blobToData(blob: Blob){
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    });
  }

  static drawCircle(canvas, context, percentage, canApply) {
    if(percentage == 0) {
      canvas.style.visibility = "hidden";
    } else {
      canvas.style.visibility = "visible";
      const color = '#0EC922'
      var posX = canvas.width / 2,
        posY = canvas.height / 2,
        oneProcent = 360 / 100,
        result = oneProcent * percentage,
        radius = (canvas.height / 2) - 10;
  
      context.lineCap = 'round';
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.strokeStyle = color;
      context.lineWidth = '10';
      context.arc(posX, posY, radius, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + result));
      context.stroke();
    }

  }

  public static calculateOverAllFeedback(candidate, totalQuestions){
    let champion = 0;
    let positive = 0;

    for (const answer of candidate?.answers || []) {
      if (answer.validation_feedback == 'positive')
        positive++;
      if (answer.validation_feedback == 'champion')
        champion++;
    }

    if (candidate.contact_validation_feedback == 'champion')
      champion++;
    if (candidate.contact_validation_feedback == 'positive')
      positive++;

    if (candidate.upload_validation_feedback == 'champion')
      champion++;
    if (candidate.upload_validation_feedback == 'positive')
      positive++;

    if (candidate.job_info_validation_feedback == 'champion')
      champion++;
    if (candidate.job_info_validation_feedback == 'positive')
      positive++;

    if (totalQuestions / 2 < champion)
      return "CHAMPION";
    if (totalQuestions / 2 < champion + positive)
      return "POSITIVE";
    return "NORMAL";
  }
}
