import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {ModalController} from "@ionic/angular";
import {ExecutorService} from "../../services/executor.service";

@Component({
  selector: 'app-iframe-modal',
  templateUrl: './iframe-modal.component.html',
  styleUrls: ['./iframe-modal.component.scss'],
})
export class IframeModalComponent implements OnInit {

  @Input() url;
  errorFlag = false;

  constructor(private spinner: NgxSpinnerService,
              public executor: ExecutorService,
              private modalController: ModalController) { }

  ngOnInit() {}

  async hideSpinner() {
    await this.spinner.hide();
  }

  async error(){
    await this.spinner.hide();
    this.errorFlag = true;
  }

  closeModal() {
    return this.modalController.dismiss();
  }
}
