import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss'],
})
export class CvComponent implements OnInit {

  @Output() navigateToHomeScreen = new EventEmitter<string>();
  @Input() cv: any;

  formatBackgroundColor: string = "#ECF9F4";
  structureBackgroundColor: string = "#FFFAEF";
  contentBackgroundColor: string = "#FFEFEF";
  readabilityBackgroundColor: string = "#FFFAEF";

  gradientColors: any;

  constructor() { }

  ngOnInit() {
    this.gradientColors = {red: "#FFEFEF", orange: "#FFFAEF", green: "#ECF9F4"};
  }

  navigateBackToHomeScreen() {
    this.navigateToHomeScreen.emit("FeedbackHomeScreen");
  }

}
