import { Component, ViewEncapsulation, OnInit, HostListener, ViewChild, ViewChildren, QueryList, OnDestroy, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { NavigationEnd, Router } from '@angular/router';
import {ModalController, ToastController, IonSelect, IonInput, ActionSheetController} from "@ionic/angular";
import {NgxSpinnerService} from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {map, takeUntil, finalize, distinctUntilChanged} from "rxjs/operators";
import SwiperCore, { EffectCoverflow, EffectFlip, Pagination, Manipulation } from "swiper";
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import { AbstractComponent } from '../@LogOn/components/abstract.component';
import { ExecutorService } from '../@LogOn/services/executor.service';
import { GeneralService } from '../@LogOn/services/general.service';
import {LanguageModalComponent} from "../@LogOn/modals/language-modal/language-modal.component";
import {SessionStorage} from "../../app/@LogOn/services/session-storage";

@Component({
  selector: 'app-simple-jap',
  templateUrl: './simple-jap.component.html',
  styleUrls: ['./simple-jap.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimpleJapComponent extends AbstractComponent implements OnInit, AfterViewInit, OnDestroy {

  // TODO: Track the user behavior for the statistic insights?

  showLandingPage: boolean;

  @ViewChild('languageSelection', { static: false }) languageSelection: IonSelect;
  @ViewChild('firstNameInput', {  static: false }) firstNameInput: IonInput;
  @ViewChild('lastNameInput', {  static: false }) lastNameInput: IonInput;
  @ViewChild('emailInput', {  static: false }) emailInput: IonInput;
  @ViewChildren("flipSwipers") flipSwipers: QueryList<any>;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;

  uploadType = 'OTHER';
  uploadActionSheet = null;
  uploads$: Observable<any[]>;
  noUserUpload = false;

  allowSlidePrev: boolean = false;
  allowSlideNext: boolean = true;
  isFirstSlide: boolean;
  isLastSlide: boolean;

  currentQuestion$: Observable<any>;
  currentQuestion: any;
  answeredQuestions$: Observable<any[]>;
  questionNumber: number;

  currentQuestionIndex: number = 0;

  contactForm: FormGroup;

  selectedAnswer: string[] = [];
  initialLoading: boolean = true;

  messageControl: FormControl = new FormControl();

  applicantImageBaseString: any;
  uploadedImageUrl: string;

  applicationWillBeSent: boolean;
  continueWithQuestionsClicked: boolean;

  planeBtnPath: string = "../../assets/simple-jap/pink-plane-button.svg";

  progressBarColor: string;
  progressBarValue: number = 0.1;

  showSlideContainerBackground: boolean = false;
  showFinishedAllQuestionsScreen: boolean = false;

  isPreview: boolean;
  previewEnd: boolean = false;

  showMoreBenefits: boolean = false;

  showDocuments: boolean = false;

  availableLanguages = [
    {
      code: 'de',
      name: 'German'
    },
    {
      code: 'de-informal',
      name: 'German Informal'
    },
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'cs',
      name: 'Czech'
    },
    {
      code: 'fr',
      name: 'French'
    },
    {
      code: 'es',
      name: 'Spanish'
    },
    {
      code: 'pl',
      name: 'Polish'
    },
    {
      code: 'it',
      name: 'Italian'
    },
    {
      code: 'uk',
      name: 'Ukrainian'
    }
  ];
  
  selectedLanguage;
  countdownStarted: boolean = false;

  companyLogo;
  titleImage;
  showTitleImage;

  jobDescriptionIsPlainText: boolean;
  jobWorkingsIsPlainText: boolean;
  jobRequirementsIsPlainText: boolean;

  job$: Observable<any> = this.executor.candidate$.pipe(map(candidate => {
    return candidate.job;
  }));

  goToIs999: boolean = false;
  currQuestion: any;

  manuallyApply: boolean = false;

  constructor(public executor: ExecutorService,
              public translate: TranslateService,
              private formBuilder: FormBuilder,
              public toastController: ToastController,
              private spinner: NgxSpinnerService,
              private router: Router,
              public modalService: GeneralService,
              protected sessionStorage: SessionStorage,
              private actionSheetCtrl: ActionSheetController,
              private modalController: ModalController,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.goToIs999 = sessionStorage.getItem("goToIs999") == "true";
    if(this.sessionStorage.getItem('source') === 'preview') {
      this.isPreview = true;
    }
    let candidate = this.executor.getCandidate();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Handle URL change here
        this.showDocuments = this.router.url.includes("upload");
        if (this.showDocuments) {
          this.selectImageSource();
        }
      }
    });
    this.uploads$ = this.executor.candidate$
      .pipe(map(candidate => {
        return (candidate.uploads || []).map(upload => {
          this.noUserUpload = false;
          return {...upload, file_type: this.getFileType(upload.mime_type)};
        })
      }));

    this.companyLogo = candidate.job.logo?.url;
    this.titleImage = candidate.job.header_image?.url;
    this.showTitleImage = candidate.job.show_header_image == 1;
    this.setJobDescription();
    this.uploadedImageUrl = ""
    candidate.uploads?.forEach(element => {
      if (element.mime_type == "image/png") {
        this.uploadedImageUrl = element.url;
      }
    });
    if (candidate.answers?.length > 0) {
      this.continueWithQuestionsClicked = sessionStorage.getItem("continueWithQuestionsClicked") === "true";
      this.questionNumber = candidate.answers.length + 1;
      this.showLandingPage = false;

      if(sessionStorage.getItem("showFinishedAllQuestionsScreen") && sessionStorage.getItem("showFinishedAllQuestionsScreen") === "true")
      {
        this.showFinishedAllQuestions();
      }
      if(sessionStorage.getItem("startApplicationCountdown") && sessionStorage.getItem("startApplicationCountdown") === "true")
      {
        this.startApplicationCountdown();
      }

    } else {
      if (sessionStorage.getItem("continueWithQuestionsClicked")) {
        sessionStorage.removeItem("continueWithQuestionsClicked");
      }
      this.showLandingPage = candidate.job.skip_job_info == 0 && sessionStorage.getItem("applyNowClicked") != candidate.job.ad_id;
      this.questionNumber = 1;

    }
    this.contactForm = this.formBuilder.group({
      first_name: [''],
      last_name: [''],
      email: ['', [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]],
      phone_obj: [''],
    });

    this.onEmailChanges();

    // install Swiper modules
    SwiperCore.use([EffectCoverflow, EffectFlip, Pagination, Manipulation]);

    this.currentQuestion$ = this.executor.candidate$.pipe(
      map(() => this.executor.getCurrentQuestion()));
      
    this.executor.candidate$.subscribe(() => {
      this.currQuestion = this.executor.getCurrentQuestion();
    });

    this.answeredQuestions$ = this.executor.candidate$.pipe(
      map(item => item.answers));
  }

  getFileType(mimeType) {
    switch (mimeType) {
      case 'application/pdf':
        return 'PDF';
      case 'text/plain':
        return 'TEXT';
      default:
        return 'DOC';
    }
  }

  backToTheQuestions() {
    this.router.navigate(['/simple-jap']);
  }

  navigateToUploadSection() {
    this.router.navigate(['/simple-jap/upload']);
  }

  async selectImageSource() {

    await this.translate.get('app_upload_cv').toPromise();
    const buttons = [
      {
        text: await this.translate.instant('app_upload_cv'),
        icon: 'cloud-upload',
        handler: () => {
          this.fileInput.nativeElement.click();
          this.uploadType = 'OTHER';
        }
      },
      {
        text: await this.translate.instant('app_upload_upload_certificate'),
        icon: 'document',
        handler: () => {
          this.fileInput.nativeElement.click();
          this.uploadType = 'CERTIFICATE';
        }
      },
      {
        text: await this.translate.instant('app_upload_upload_driving'),
        icon: 'reader',
        handler: () => {
          this.fileInput.nativeElement.click();
          this.uploadType = 'DRIVING_LICENSE';
        }
      },
      {
        text: await this.translate.instant('app_other'),
        icon: 'albums',
        handler: () => {
          this.fileInput.nativeElement.click();
          this.uploadType = 'OTHER';
        }
      }
    ];

    this.fileInput.nativeElement.value = null;
    this.uploadActionSheet = await this.actionSheetCtrl.create({
      header: this.translate.instant('app_upload_select_source'),
      buttons,
      cssClass:'w-full',

    });
    await this.uploadActionSheet.present();
  }

  async delete(id) {
    this.spinner.show();

    this.executor.deleteCandidateUpload(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.spinner.hide();
        let candidate = this.executor.getCandidate();
        this.uploadedImageUrl = "";
        if (candidate.uploads?.length == 0) {
          this.noUserUpload = true;
        } else {
          candidate.uploads?.forEach(element => {
            if (element.mime_type == "image/png") {
              this.uploadedImageUrl = element.url;
            }
          });
        }
      }, error => this.spinner.hide());
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      if (this.countdownStarted) {
        this.cancelApplication();
      }
    }
    if (event.key == 'Tab') {
      event.preventDefault(); 
      let question = this.executor.getCurrentQuestion();
      if (question?.type === 'NAME_EMAIL' || question?.type === 'NAME_PHONE' || question?.type === 'CONTACT_ALL') {
        if (document.activeElement instanceof HTMLButtonElement) {
          const phoneInput = document.querySelector('ion-intl-tel-input input') as HTMLInputElement;
          phoneInput.focus();
        }
      }
    }
    if(event.key == 'Enter') {
      let question = this.executor.getCurrentQuestion();
      if (this.getNextBtnState(question) && question?.type != 'TEXT') {
        this.confirmAnswer(question);
      }
    }
  }

  getNextBtnState(question: any) {
    return (question?.type === 'TEXT' && this.messageControl.value?.length > 0) ||
            (question?.type === 'BUTTONS' && question.multiselect && this.selectedAnswer?.length > 0) ||
            (question?.type === 'NAME_EMAIL' && this.contactForm.get('email').value?.length > 0 && !this.errorControl.email.errors?.pattern) ||
            (question?.type === 'NAME_PHONE' && this.contactForm.get('phone_obj').value?.internationalNumber) ||
            (question?.type === 'CONTACT_ALL' && 
            ((this.contactForm.get('phone_obj').value?.internationalNumber && this.contactForm.get('email').value?.length > 0 && !this.errorControl.email.errors?.pattern) ||
            (this.contactForm.get('phone_obj').value?.internationalNumber && this.contactForm.get('email').value?.length == 0) ||
            (this.contactForm.get('email').value?.length > 0 && !this.errorControl.email.errors?.pattern)));
  }

  focusNextInput(name: string) {
    let question = this.executor.getCurrentQuestion();
    if (question?.type === 'NAME_EMAIL') {
      if (name == "first-name") {
        this.lastNameInput.setFocus();
      } else if (name == "last-name") {
        this.emailInput.setFocus();
      } else if (name == "email") {
        this.firstNameInput.setFocus();
      }
    } else if (question?.type === 'NAME_PHONE') {
      if (name == "first-name") {
        this.lastNameInput.setFocus();
      } else if (name == "last-name") {
        const phoneInput = document.querySelector('ion-intl-tel-input input') as HTMLInputElement;
        phoneInput.focus();
      } else if (name == "phone") {
        this.firstNameInput.setFocus();
      }
    } else if (question?.type === 'CONTACT_ALL') {
      if (name == "first-name") {
        this.lastNameInput.setFocus();
      } else if (name == "last-name") {
        this.emailInput.setFocus();
      } else if (name == "email") {
        const phoneInput = document.querySelector('ion-intl-tel-input input') as HTMLInputElement;
        phoneInput.focus();
      } else if (name == "phone") {
        this.firstNameInput.setFocus();
      }
    }
  }

  setJobDescription() {
    let job = this.executor.getCandidate().job.languages[this.executor.getLanguageIndex()];
    this.jobDescriptionIsPlainText = !/<[a-z][\s\S]*>/i.test(job?.description);
    this.jobWorkingsIsPlainText = !/<[a-z][\s\S]*>/i.test(job?.description);
    this.jobRequirementsIsPlainText = !/<[a-z][\s\S]*>/i.test(job?.description);
  }

  ngAfterViewInit() {
    this.trackProgressBar();
    if((this.executor.getCandidate().uploads?.length || 0) == 0){
      this.noUserUpload = true;
    }
  }

  onShowMoreBenefitsClick() {
    this.showMoreBenefits = !this.showMoreBenefits;
  }

  onEmailChanges() {
    this.contactForm.get('email').valueChanges.pipe(distinctUntilChanged()).subscribe(val => {
      this.contactForm.controls['email'].setValue(val.trim());
    });
  }

  getCurrency(salaryCurrency) {
    return getCurrencySymbol(salaryCurrency, "wide");
  }

  get errorControl() {
    return this.contactForm.controls;
  }

  setSelectedLanguage(code = null) {
    this.selectedLanguage = this.availableLanguages.find(language => language.code == (code || this.executor.getLanguage()));
  }

  languageChanged(event) {
    this.setSelectedLanguage(event.target.value);
    this.confirmLanguageChange();
  }

  async presentLanguageModal() {
    if (this.executor.getCandidate().answers?.length > 0) {
      const modal = await this.modalController.create({
        component: LanguageModalComponent,
        initialBreakpoint: 0.5,
        cssClass: 'custom-modal'
      });
      return await modal.present();
    } else {
      this.languageSelection.open();
    }
  }

  async confirmLanguageChange() {
    await this.spinner.show();
    this.executor.changeLanguage(this.selectedLanguage.code)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(data => {
        this.questionNumber = 1;
        this.backToTheQuestions();
        this.cancelApplication();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event) {
    let landingPageContainerMarginTop = (window.innerHeight - document.getElementById('simple-jap-container').offsetHeight) / 2;
    if (this.showLandingPage) {
      let answerBtnYPosition = document.querySelector('.answer-questions').getBoundingClientRect().y;
      let difference = landingPageContainerMarginTop - answerBtnYPosition;

      if ((screen.width < 769 && answerBtnYPosition < -20) || (screen.width > 768 && difference > 20)) {
        document.getElementById("sticky-button").style.transform = "translateY(0)";
      } else {
        document.getElementById("sticky-button").style.transform = "translateY(100%)";
      }
    }
  }

  showFirstQuestion() {
    if (this.showLandingPage) {
      this.sessionStorage.setItem("applyNowClicked", this.executor.getCandidate().job.ad_id.toString());
    }
    this.showLandingPage = !this.showLandingPage;
  }

  flipQuestionCard() {
    this.flipSwipers.toArray()[this.currentQuestionIndex].swiperRef.slideNext();
  }

  flipToQuestion() {
    this.flipSwipers.toArray()[this.currentQuestionIndex].swiperRef.slidePrev();
  }

  isOptionSelected(option, options: any[] = null) {
    return (options || this.selectedAnswer).indexOf(option) !== -1;
  }

  selectOption(answer, question) {
    if (!question.multiselect) {
      this.selectedAnswer = [answer];
      this.confirmAnswer(question);
    } else {
      if (this.selectedAnswer.indexOf(answer) !== -1)
        this.selectedAnswer = this.selectedAnswer.filter(item => item != answer);
      else
        this.selectedAnswer.push(answer);
    }
  }

  async uploadFile(event) {
    const file = event && event.target ? event.target.files[0] : event;
    await this.spinner.show();

    if(!file)
      return;

    // delete all uploaded images from before
    let candidate = this.executor.getCandidate();
    candidate.uploads?.forEach(element => {
      if (element.mime_type == "image/png") {
        this.executor.deleteCandidateUpload(element.id).subscribe(() => this.uploadedImageUrl = "");
      }
    });

    let uploadType = "OTHER";
    this.executor.candidateUpload(uploadType, file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.uploadedImageUrl = data.url;
        this.spinner.hide()
      }, error => this.spinner.hide());
  };

  async uploadDocument(event) {
    const file = event && event.target ? event.target.files[0] : event;
    await this.spinner.show();

    if(!file)
      return;
    this.executor.candidateUpload(this.uploadType, file)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        this.spinner.hide();
        this.cdr.detectChanges();
      } , error => this.spinner.hide());

    this.uploadActionSheet.dismiss();
  };

  base64ToBlob(image) {
    this.applicantImageBaseString = image.base64String;
    const rawData = atob(this.applicantImageBaseString);
    const bytes = new Array(rawData.length);
    for (let x = 0; x < rawData.length; x++) {
      bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    return new Blob([arr], {type: 'image/png'});
  }

  async takePicture() {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
    });


    const file = new File([this.base64ToBlob(image)], "Logon" +  Math.floor(Math.random() * (10000 - 1 + 1)) + 1, {
      lastModified: Date.now(),
      type: this.base64ToBlob(image).type,
    });

    await this.uploadFile(file);
  }

  async confirmAnswer(question, skip: boolean = false) {
    this.flipToQuestion();
    this.spinner.show();
    let answer = null;
    let obj = null;
    let name = this.contactForm.get('first_name')?.value +
              (this.contactForm.get('first_name')?.value && this.contactForm.get('last_name')?.value ? " " : "") +
              this.contactForm.get('last_name')?.value;

    if (!skip) {
      switch (question.type) {
        case 'PHONE':
          answer = this.messageControl.value?.internationalNumber;
          obj = this.messageControl.value;
          break;
        case 'DATE':
        case 'TIME':
          answer = this.messageControl.value;
          break;
        case 'BUTTONS':
          answer = (this.messageControl.value || this.selectedAnswer).join(';');
          break;
        case 'NAME_PHONE':
          answer = [name, this.contactForm.get('phone_obj')?.value.internationalNumber].join(";");
          obj = this.contactForm.get('phone_obj')?.value;
          break;
        case 'NAME_EMAIL':
          answer = [name, this.contactForm.get('email')?.value].join(";");
          break;
        case 'CONTACT_ALL':
          let internationalNumber = this.contactForm.get('phone_obj')?.value?.internationalNumber ?
                                    this.contactForm.get('phone_obj').value.internationalNumber : "";
          answer = [name, this.contactForm.get('email')?.value, internationalNumber].join(";");
          obj = this.contactForm.get('phone_obj')?.value;
          break;
        case 'TEXT':
          answer = this.messageControl.value.replace(/[\n\t\r\b\f\v]/g, ' ');
          break;
        default:
          answer = this.messageControl.value;
      }
    }

    this.executor.answer(question.id, answer, obj)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.goToIs999 = res.go_to == 999;
        sessionStorage.setItem("goToIs999", this.goToIs999.toString());
        this.selectedAnswer = [];
        this.messageControl.setValue(null);
        if (this.goToIs999 && res.validation_action != "UPLOAD") {
          this.showSlideContainerBackground = true;
          this.showFinishedAllQuestionsScreen = true;
          sessionStorage.setItem("showFinishedAllQuestionsScreen", "true");
          this.continueWithQuestionsClicked = false;
          sessionStorage.setItem("continueWithQuestionsClicked", "false");
          setTimeout(() => {
            this.startApplicationCountdown();
          }, 6000);
        }
        this.spinner.hide();
      }, error => {});
    this.questionNumber += 1;
  }

  showFinishedAllQuestions()
  { 
    this.showSlideContainerBackground = true;
    this.showFinishedAllQuestionsScreen = true;
    sessionStorage.setItem("showFinishedAllQuestionsScreen", "true");
    this.continueWithQuestionsClicked = false;
    sessionStorage.setItem("continueWithQuestionsClicked", "false");
    setTimeout(() => {
      this.startApplicationCountdown();
    }, 6000);
  }

  onNextClickFromDocuments() {
    if (this.goToIs999) {
      // APPLY
      this.showSlideContainerBackground = true;
      this.showFinishedAllQuestionsScreen = true;
      sessionStorage.setItem("showFinishedAllQuestionsScreen", "true");
      this.continueWithQuestionsClicked = false;
      sessionStorage.setItem("continueWithQuestionsClicked", "false");
      setTimeout(() => {
        this.startApplicationCountdown();
      }, 2000);
    } else {
      this.backToTheQuestions();
    }
  }

  async cancelApplication() {
    await this.spinner.show();
    this.countdownStarted = false;
    // clearing all timeouts and intervals
    const highestId = window.setTimeout(() => {
      for (let i = highestId; i >= 0; i--) {
        window.clearInterval(i);
      }
    }, 0);
    document.getElementById("application-will-be-sent").style.display = "none";
    const runner: HTMLElement = document.querySelector('#runner');
    const briefcase: HTMLElement = document.querySelector('#briefcase');
    const confetti: HTMLElement = document.querySelector('#confetti-1-small');
    if (runner) {
      runner.style.setProperty('display', 'block');
    }
    if (briefcase) {
      briefcase.style.setProperty('display', 'block');
    }
    if (confetti) {
      confetti.style.setProperty('display', 'none');
    }
    this.planeBtnPath = "../../assets/simple-jap/pink-plane-button.svg";
    this.executor.restartChat(this.executor.getLanguage()).toPromise().then(data => {
      let candidate = this.executor.getCandidate()
      // delete all uploaded documents from before
      candidate.uploads?.forEach(element => {
        this.executor.deleteCandidateUpload(element.id).subscribe();
      });
      this.uploadedImageUrl = "";
      /* this.contactForm = this.formBuilder.group({
        first_name: [''],
        last_name: [''],
        email: ['', [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]],
        phone_obj: [''],
      }); */
      this.showSlideContainerBackground = false;
      this.questionNumber = candidate.answers.length + 1;
      this.continueWithQuestionsClicked = false;
      sessionStorage.setItem("continueWithQuestionsClicked", "false");
      sessionStorage.setItem("startApplicationCountdown", "false");

      this.applicationWillBeSent = false;
      this.showLandingPage = candidate.job.skip_job_info == 0 && sessionStorage.getItem("applyNowClicked") != candidate.job.ad_id;
      this.router.navigateByUrl("/simple-jap");
      this.spinner.hide()
    });
  }

  async apply() {
    try {
      await this.spinner.show(); 
      this.executor.apply({
        full_name: this.executor.getCandidate().full_name,
        email: this.executor.getCandidate().email,
        phone: this.executor.getCandidate().phone,
        phone_obj: this.executor.getCandidate().phone_obj,
        address: this.executor.getCandidate().address,
      }).pipe(takeUntil(this.destroyed$), finalize(() => this.spinner.hide()))
        .subscribe(
          data => {
            if (typeof (window as any).fbq === 'function') {
              (window as any).fbq('track', 'ApplicationSent', { cmpId: this.executor.getCandidate().utm_source });
            }
            this.router.navigate(['/applied-success']);
          },
          error => {
          this.router.navigate(['/tabs/apply'])
            //this.router.navigate(['/simple-jap'])
          }
        );
    } catch (error) {
    }
    finally {
      this.sessionStorage.clear(); 
    }
  }

  executeApply()
  {
    this.manuallyApply=true;
    this.countdownStarted = false;
    // clearing all timeouts and intervals
    const highestId = window.setTimeout(() => {
      for (let i = highestId; i >= 0; i--) {
        window.clearInterval(i);
      }
    }, 0);
    this.apply();
  }

  continueWithQuestions() {
    this.continueWithQuestionsClicked = true;
    sessionStorage.setItem("continueWithQuestionsClicked", "true");
  }

  trackProgressBar() {
    this.executor.percentage$.subscribe(res => {
      if (!(res === this.progressBarValue*100)) {
        let progressBar: HTMLElement = document.querySelector('.ion-progress-bar');
        let runner: HTMLElement = document.querySelector('#runner');
        let briefcase: HTMLElement = document.querySelector('#briefcase');
        let confetti: HTMLElement = document.querySelector('#confetti-1-small');

        if (res == 0) {
          this.progressBarValue = 0.1;
          runner?.style.setProperty('left', "calc(10% - 16px)");
        } else if (res > 0) {
          this.progressBarValue = res / 100;
          runner?.style.setProperty('display', 'block');
          runner?.style.setProperty('left', "calc(" + res.toString() + "% - 16px)");
        }

        if (res !== 100) {
          briefcase?.style.setProperty('display', 'block');
          confetti?.style.setProperty('display', 'none');
        }

        if (res <= 30) {
          progressBar?.style.setProperty('--progress-background', '#FF7E45');
          progressBar?.style.setProperty('--buffer-background', 'rgba(255, 126, 69, 0.2)');
        } else if (res > 30 && res < 80) {
          progressBar?.style.setProperty('--progress-background', '#FFEA45')
          progressBar?.style.setProperty('--buffer-background', 'rgba(255, 234, 69, 0.2)')
        } else if (res >= 80 && res != 100) {
          progressBar?.style.setProperty('--progress-background', '#CDFF45')
          progressBar?.style.setProperty('--buffer-background', 'rgba(205, 255, 69, 0.2)');
        } else if (res == 100) {
          runner?.style.setProperty('display', 'none');
          briefcase?.style.setProperty('display', 'none');
          confetti?.style.setProperty('display', 'block');
          progressBar?.style.setProperty('--progress-background', 'linear-gradient(45deg, #ff278d, #5223ca)');
          progressBar?.style.setProperty('--buffer-background', 'rgba(205, 255, 69, 0.2)');
        }
      }
    });
  }

  startApplicationCountdown() {
    //see 22.09.2023 added for fixing bug of re-applying double
    if (this.manuallyApply===true)
        return;

    this.countdownStarted = true;
    this.showSlideContainerBackground = true;
    this.showFinishedAllQuestionsScreen = false;
    sessionStorage.setItem("startApplicationCountdown", "true");
    sessionStorage.setItem("showFinishedAllQuestionsScreen", "false");
    let deg = 47
    let seconds = 6;
    let countdown = document.getElementById("countdown");
    let secondsText = document.getElementById("seconds");
    countdown.innerHTML = "&nbsp;";
    secondsText.innerHTML = "&nbsp;";

    let planeButton = document.getElementById("plane-button");
    planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
    let confetti = document.getElementById("confetti-2");

    let canApplyPlaneButton = document.getElementById("sent-application");
    let cancelApplicationButton = document.getElementById("cancel-application");
    document.getElementById("application-will-be-sent").style.display = "flex";
    // continueWithQuestionsClickedset to true in order to hide the can apply overlay.
    this.continueWithQuestionsClicked = true;
    sessionStorage.removeItem("continueWithQuestionsClicked");
    this.applicationWillBeSent = true;

    setTimeout(() => {

      // 6
      setTimeout(() => {
        countdown.innerHTML = seconds.toString();
        secondsText.innerHTML = this.translate.instant('app_simple_jap_seconds');
      }, 800)

      // 5, 4, 3
      setTimeout(() => {
        let planeInterval = setInterval(() => {
          setTimeout(() => {
            seconds -= 1
            countdown.innerHTML = seconds.toString();
          }, 200)
          if (deg === -13) {
            clearInterval(planeInterval)
          }
          deg -= 30;
          planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
        }, 1000)
      }, 800)

      // 2
      setTimeout(() => {
        planeButton.style.transitionDuration = "0.325s";

        setTimeout(() => {
          seconds -= 1
          countdown.innerHTML = seconds.toString();
        }, 200)

        let secondLastAnimation = setInterval(() => {
          if (deg === -43) {
            deg -= 11;
            planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
          } else if (deg === -54) {
            deg += 22
            planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
            clearInterval(secondLastAnimation)
          }
        }, 500)
      }, 4800)

      // 1
      setTimeout(() => {
        planeButton.style.transitionDuration = "0.12s";

        setTimeout(() => {
          seconds -= 1
          countdown.innerHTML = seconds.toString();
          secondsText.innerHTML = this.translate.instant('app_simple_jap_second');;
        }, 200)

        let lastSecondAnimation = setInterval(() => {
          if (deg === -32) {
            deg -= 39;
            planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
          } else if (deg === -71) {
            deg += 41;
            planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
          } else if (deg === -30) {
            deg -= 35;
            planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
          } else if (deg === -65) {
            deg += 22;
            planeButton.style.transform = "rotate(" + deg.toString() + "deg)"
            this.planeBtnPath = "../../assets/simple-jap/green-plane-button.svg";
          } else if (deg === -43) {
            clearInterval(lastSecondAnimation)
          }
        }, 200)
      }, 5800)

      setTimeout(() => {
        cancelApplicationButton.style.visibility = "hidden";
        canApplyPlaneButton.style.visibility= "hidden";
      }, 6900)

      setTimeout(() => {
        planeButton.style.transitionDuration = "0.75s";
        planeButton.style.transform = "translateY(-1000px)";
        countdown.style.visibility = "hidden";
        secondsText.style.visibility = "hidden";
      }, 7100)

      setTimeout(() => {
        confetti.style.visibility = "visible";
      }, 7300)

      setTimeout(() => {
        if (this.isPreview || (this.executor.getCandidate().job?.status == 'SAVED')) {
          this.previewEnd = true;
        } else if (this.countdownStarted) {
            this.apply();
        }
      }, 8150)

    }, 400)
  }
}
