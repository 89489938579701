import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-job-questions',
  templateUrl: './job-questions.component.html',
  styleUrls: ['./job-questions.component.scss'],
})
export class JobQuestionsComponent implements OnInit {

  @Output() navigateToHomeScreen = new EventEmitter<string>();
  @Input() jobQuestions: any;
  @ViewChild('jobQuestionsSlider') slides: IonSlides;

  slideNextDisabled: boolean;
  slidePrevDisabled: boolean;

  emojisPaths: any;

  constructor() { }

  ngOnInit() {
    this.slidePrevDisabled = true;
    this.emojisPaths = {
      0: "../../../../assets/icons/feedback/job-question-woman-short-hair.png",
      1: "../../../../assets/icons/feedback/job-question-woman.png",
      2: "../../../../assets/icons/feedback/job-question-man.png",
      3: "../../../../assets/icons/feedback/job-question-woman-short-hair.png",
      4: "../../../../assets/icons/feedback/job-question-woman.png",
      5: "../../../../assets/icons/feedback/job-question-man.png"
    };
  }

  navigateBackToHomeScreen() {
    this.navigateToHomeScreen.emit("FeedbackHomeScreen");
  }

  slideChanged() {
    this.slides.getActiveIndex().then(activeIndex => {
      this.slides.length().then(length => {
        this.slideNextDisabled = activeIndex === (length - 1);
        this.slidePrevDisabled = activeIndex === 0;
      });
    });
  }

  slideNext() {
    this.slides.slideNext();
    this.slideChanged();
  }
  
  slidePrev() {
    this.slides.slidePrev();
    this.slideChanged();
  }

}
