export const COOKIE_CONTENT = [
  {
    key:"facebook_tracking_pixel_id",
    "Name": "Facebook",
    "Cookie Name": "fr, tr, _fbp",
    "Aufbewahrungsdauer": "3 Monate, Session, 3 Monate",
    "Typ": "HTTP, Pixel, HTTP",
    "Zweck": "Dies ist eine Tracking -Technologie, die von Facebook angeboten und von anderen Facebook-Diensten wie Facebook Custom Audiences verwendet wird, um eine Reihe von Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote dritter Werbetreibender.",
    "Verarbeitendes Unternehmen": "Facebook Ireland Limited\n4 Grand Canal Square, Grand Canal Harbour, Dublin, D02, Ireland",
    "Cookie-Richtlinien des Datenverarbeiters": "https://www.facebook.com/policies/cookies",
    "Datenschutzbeauftragter": "https://www.facebook.com/help/contact/540977946302970",
    "Genutzte Technologien": "Pixel-Tags\nCookies",
    "Erhobene Daten": "Angesehene Werbeanzeigen\nAngesehener Inhalt\nGeräteinformation\nGeogrfischer Standort\nHTTP-Header\nInteraktion mit Werbung, Services und Produkten\nIP- Adresse\nAngeklickte Elemente\nMarketinginformation\nNicht vertrauliche benutzerdefinierte Daten\nBesuchte Seiten\nPixel-ID\nNutzerverhalten\nFacebook Nutzer-ID\nNutzungs-/Klickverhalten\nBrowser-Informationen",
    "Rechtsgrundlage": "Art. 6 Abs. 1 s. 1 lit. a DS-GVO",
    "Ort der Verarbeitung": "Europäische Union",
    "Weitergabe an Drittländer": "Singapur, Vereinigtes Königreich, Vereinigte Staaten von Amerika",
    "Datenempfänger": "Facebook Inc.\nFacebook Ireland Limited\nSubservices\nFacebook Connect\nFBCDN Facebook\nFacebook Custom Audiences\nFacebook Audience Network\nFacebook SDK",
    "Datenschutzbestimmungen des Datenverarbeiters": "https://www.facebook.com/privacy/explanation",
    "Widerruf auf allen Domains": ""
  },
  {
    key:"instagram_pixel_id",
    "Name": "Facebook",
    "Cookie Name": "fr, tr, _fbp",
    "Aufbewahrungsdauer": "3 Monate, Session, 3 Monate",
    "Typ": "HTTP, Pixel, HTTP",
    "Zweck": "Dies ist eine Tracking -Technologie, die von Facebook angeboten und von anderen Facebook-Diensten wie Facebook Custom Audiences verwendet wird, um eine Reihe von Werbeprodukten anzuzeigen, zum Beispiel Echtzeitgebote dritter Werbetreibender.",
    "Verarbeitendes Unternehmen": "Facebook Ireland Limited\n4 Grand Canal Square, Grand Canal Harbour, Dublin, D02, Ireland",
    "Cookie-Richtlinien des Datenverarbeiters": "https://www.facebook.com/policies/cookies",
    "Datenschutzbeauftragter": "https://www.facebook.com/help/contact/540977946302970",
    "Genutzte Technologien": "Pixel-Tags\nCookies",
    "Erhobene Daten": "Angesehene Werbeanzeigen\nAngesehener Inhalt\nGeräteinformation\nGeogrfischer Standort\nHTTP-Header\nInteraktion mit Werbung, Services und Produkten\nIP- Adresse\nAngeklickte Elemente\nMarketinginformation\nNicht vertrauliche benutzerdefinierte Daten\nBesuchte Seiten\nPixel-ID\nNutzerverhalten\nFacebook Nutzer-ID\nNutzungs-/Klickverhalten\nBrowser-Informationen",
    "Rechtsgrundlage": "Art. 6 Abs. 1 s. 1 lit. a DS-GVO",
    "Ort der Verarbeitung": "Europäische Union",
    "Weitergabe an Drittländer": "Singapur, Vereinigtes Königreich, Vereinigte Staaten von Amerika",
    "Datenempfänger": "Facebook Inc.\nFacebook Ireland Limited\nSubservices\nFacebook Connect\nFBCDN Facebook\nFacebook Custom Audiences\nFacebook Audience Network\nFacebook SDK",
    "Datenschutzbestimmungen des Datenverarbeiters": "https://www.facebook.com/privacy/explanation",
    "Widerruf auf allen Domains": ""
  },
  {
    key:"advertising_id",
    "Name": "Google Ads",
    "Cookie Name": "ads/ga-audiences",
    "Aufbewahrungsdauer": "Session",
    "Typ": "Pixel",
    "Zweck": "Dies ist ein Werbeservice von Google. Wird verwendet, um Besucher, die aufgrund ihres Online-Verhaltens auf verschiedenen Websites wahrscheinlich zu Kunden werden, erneut anzusprechen.",
    "Verarbeitendes Unternehmen": "Google Ireland Limited\nGoogle Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland",
    "Cookie-Richtlinien des Datenverarbeiters": "https://policies.google.com/technologies/cookies?hl=en",
    "Datenschutzbeauftragter": "https://support.google.com/policies/contact/general_privacy_form",
    "Genutzte Technologien": "Pixel-Tags\nCookies",
    "Erhobene Daten": "Klickpfad\nEreignisse\nIP-Adresse\nReferrer URL\nTouchpoint zum Werbemittel\nTransaktionen\nUser Agent\nGesehene Seiten",
    "Rechtsgrundlage": "Art. 6 Abs. 1 s. 1 lit. a DS-GVO",
    "Ort der Verarbeitung": "Europäische Union",
    "Weitergabe an Drittländer": "Chile, Singapur, Taiwan, Vereinigte Staaten von Amerika",
    "Datenempfänger": "Alphabet Inc.\nGoogle LLC\nGoogle Ireland Limited",
    "Datenschutzbestimmungen des Datenverarbeiters": "https://policies.google.com/privacy?hl=en",
    "Widerruf auf allen Domains": "https://safety.google/privacy/privacy-controls/"
  },
  {
    key:"google_analytics_pixel_id",
    "Name": "Google Analytics",
    "Cookie Name": "collect, _ga, _gid, _dc_gtm_UA-#",
    "Aufbewahrungsdauer": "Session, 2 Jahre, 1 Tag, 1 Tag",
    "Typ": "Pixel, HTTP, HTTP, HTTP",
    "Zweck": "Dies ist ein Webanalysedienst von Google. Erzeugt statistische Daten darüber, wie der Besucher die Website nutzt.\nCookie von Google zur Steuerung der erweiterten Script- und Ereignisbehandlung.",
    "Verarbeitendes Unternehmen": "Google Ireland Limited\nGoogle Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland",
    "Cookie-Richtlinien des Datenverarbeiters": "https://policies.google.com/technologies/cookies?hl=en",
    "Datenschutzbeauftragter": "https://support.google.com/policies/contact/general_privacy_form",
    "Genutzte Technologien": "Pixel-Tags\nCookies",
    "Erhobene Daten": "IP Adresse\nNutzungsdaten\nApp-Aktualisierungen\nGeräte Informationen\nBesuchte Seiten\nDownloads\nStandortinformationen\nWidget-Interaktionen\nDatum und Uhrzeit des Besuchs\nKlickpfad\nBrowser-Informationen\nJavaScript Support\nReferrer URL\nFlash-Version\nKaufaktivität\nAggregierte Daten über die Tag-Auflösung",
    "Rechtsgrundlage": "Art. 6 Abs. 1 s. 1 lit. a DS-GVO",
    "Ort der Verarbeitung": "Europäische Union",
    "Weitergabe an Drittländer": "Chile, Singapur, Taiwan, Vereinigte Staaten von Amerika",
    "Datenempfänger": "Alphabet Inc.\nGoogle LLC\nGoogle Ireland Limited",
    "Datenschutzbestimmungen des Datenverarbeiters": "https://policies.google.com/privacy?hl=en",
    "Widerruf auf allen Domains": "https://safety.google/privacy/privacy-controls/"
  }];
