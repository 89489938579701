import {Injectable} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class AppCookieService {

  public injectedScripts = {
    gtm_pixel_id_consent:false,
    google_analytics_pixel_id: false,
    advertising_id: false,
    facebook_tracking_pixel_id: false,
    instagram_pixel_id: false
  }
  constructor(protected modalController: ModalController, private cookieService: CookieService) {
  }

  setConsentCookie(name) {
    this.cookieService.set(
      name,
      Math.random().toString(),
      new Date(new Date().setMonth(new Date().getMonth() + 12)),
      '/'
    );
  }

  hasConsent(name) {
    return this.cookieService.check(name + '_consent') || this.cookieService.check(`${name}_consent_removed`);
  }

  consentEnabled(name) {
    return this.cookieService.check(name + '_consent') && !this.cookieService.check(name + '_consent_removed');
  }

  allowCookies(name: any) {
    if (!name) {
      return false;
    }
    this.cookieService.delete(name + '_consent_removed', '/');
    this.setConsentCookie(name + '_consent');
  }


  removeCookie(name: string) {
    if (!name) return;
    this.cookieService.delete(name + '_consent', '/');
  }

  forbidCookies(name: any) {
    if (!name) {
      return false;
    }
    this.setConsentCookie(name + '_consent_removed');
  }

  injectFacebookPixel(trackingID: string, type: string, utm_source: string = null, trackingName?: string): void {
    if (this.injectedScripts[type]) return;
    console.log(type, 'injected');
      (function (f: any, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    //(window as any).fbq.disablePushState = true; //not recommended, but can be done
    (window as any).fbq('init', trackingID);
    (window as any).fbq('track', 'PageView');
    if (!!trackingName) {
      (window as any).fbq('track', trackingName, {cmpId: utm_source});
    } else {
      (window as any).fbq('track', 'ApplicationStarted', {cmpId: utm_source});
    }

    this.injectedScripts[type] = true;
  }

  injectGooglePixel(googlePixelType: | 'gtag' | 'gtm', trackingID: string, type): void {
    if (this.injectedScripts[type]) return;
    console.log(type, ' injected')
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', `https://www.googletagmanager.com/${googlePixelType}.js?id=${trackingID}`);

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;

    document.documentElement.firstChild.appendChild(gaScript);
    document.documentElement.firstChild.appendChild(gaScript2);
    this.injectedScripts[type] = true;
  }

  initialInjectForAllowedCookies(job, source) {
    const trackingPixelConfig = JSON.parse(job?.tracking_pixel_config)?.filter(trackingPixel =>
      trackingPixel?.id && trackingPixel.entry_points.includes(source));

    trackingPixelConfig.forEach(config => {
      let cookieName = 'cookie_' + config?.type + '_' + config?.id + '_consent';
      if (this.cookieService.check(cookieName) && config.id) {
        if (config.type === 'google_analytics_pixel_id') {
          // gtag for analytics
          this.injectGooglePixel('gtag', config.id, config.type)
        }
        if (config.type === 'advertising_id') {
          // gtag for advertising_id
          this.injectGooglePixel('gtag', config.id, config.type)
        }
        if (config.type === 'gtm_pixel_id') {
          // gtm for google tag manager
          this.injectGooglePixel('gtm', config.id, config.type)
        }
        if (config.type === 'facebook_tracking_pixel_id') {
          this.injectFacebookPixel(config.id, config.type, null, "ApplicationStarted")
        }
        if (config.type === 'instagram_pixel_id') {
          this.injectFacebookPixel(config.id, config.type, null, "ApplicationStarted")
        }
        this.injectedScripts[config.type] = true;
      }
    })
  }

}
