import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ModalController, ToastController, IonInput} from "@ionic/angular";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ExecutorService} from "../../services/executor.service";

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit, AfterViewInit {

  contactForm: FormGroup;
  phoneNumber = null;
  @ViewChild('emailInput', {  static: false }) emailInput: IonInput;


  constructor(private cd: ChangeDetectorRef,
              private modalController: ModalController,
              private formBuilder: FormBuilder,
              public executorService: ExecutorService,
              public toastController: ToastController) {
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      full_name: [''],
      full_name_1: [''],
      email: [''],
      phone_obj: [''],
    });


    this.contactForm.patchValue(this.executorService.getCandidate());
  }

  ngAfterViewInit() {

  }

  focused(event) {
    setTimeout(() => {
      event.target.scrollIntoView({
        behavior: 'smooth'
      });
    }, 300)
  }

  closeModal() {
    this.modalController.dismiss();
  }

  focusEmailInput(event) {
    // TODO: focus also ion-intl-tel-input when clicking enter on email input. (The element seems not to have a focus function..)
    this.emailInput.setFocus();
  }

  onConfirm() {
    this.executorService.setContactData({
      ...this.contactForm.getRawValue(),
      phone: this.contactForm.get('phone_obj')?.value?.internationalNumber
    }).subscribe(res => {
      this.closeModal();
    }, async err => {
      if (err.status === 400) {
        const toast = await this.toastController.create({
          message: Object.values(err.error.data).join(', '),
          duration: 2000
        });
        toast.present();
      }
    });
  }
}
