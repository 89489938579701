import {Component, OnInit, Output, Input, EventEmitter, ViewChild, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import SwiperCore, {SwiperOptions, Pagination, Navigation} from 'swiper';
import {SwiperComponent} from "swiper/angular";

@Component({
  selector: 'app-match',
  templateUrl: './match.component.html',
  styleUrls: ['./match.component.scss'],
})
export class MatchComponent implements OnInit {

  @Output() navigateToHomeScreen = new EventEmitter<string>();
  @Input() match: any;
  @Input() detail: any;

  @ViewChild('careerLevelSwiper', {static: false}) careerLevelSwiper?: SwiperComponent;
  config: SwiperOptions;
  careerLevelSwiperIsBeginning: boolean;
  careerLevelSwiperIsEnd: boolean;

  // education
  educationLevelRequestsExist: boolean;
  educationLevelExistInCV: any;
  schoolEducationRequested: boolean;
  vocationalTrainingRequested: boolean;
  studiesRequested: boolean;
  studiesPlusRequested: boolean;
  educationQualifications: any;

  // occupations
  occupations: any;
  currentOccupationIndex: number;
  selectedOccupationCvLevel: any;
  selectedOccupationAdLevel: any;
  occupationLevelsCanBeCompared: boolean;

  // skills
  skills: any;
  showSkillContent: boolean;

  // occupation group
  mainOccupationGroup: any;
  otherOccupationGroups: any;
  showOccupationGroup: boolean;

  gradientColors: any;
  gradientDegrees: any;
  selectedFeedback: number;

  constructor(public translate: TranslateService,
              public cd: ChangeDetectorRef) {
  }

  ngOnInit() {

    // install Swiper modules
    SwiperCore.use([Pagination, Navigation]);
    this.config = {
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev'
      },
      pagination: {clickable: true},
    };
    this.careerLevelSwiperIsBeginning = true;

    this.gradientColors = {red: "#ff5432", orange: "#FCAD41", green: "#23C915"};
    this.gradientDegrees = {red: "50deg", orange: "180deg", green: "360deg"};
    this.prepareMatchData();
    this.generateIdForMatchDetails();
    this.selectedFeedback = null;
  }

  generateIdForMatchDetails() {
    this.match.education['toggleId'] = 1;
    this.match.skills['toggleId'] = 2;
    this.match.professional_group['toggleId'] = 3;
    this.match.occupations.forEach(item => {
      item.toggleId = 4;
    })
  }

  navigateBackToHomeScreen() {
    this.navigateToHomeScreen.emit("FeedbackHomeScreen");
  }

  onCareerLevelSlideChange() {
    this.currentOccupationIndex = this.careerLevelSwiper.swiperRef.activeIndex;
    this.setOccupationLevels(this.currentOccupationIndex);
    this.careerLevelSwiperIsBeginning = this.careerLevelSwiper.swiperRef.isBeginning;
    this.careerLevelSwiperIsEnd = this.careerLevelSwiper.swiperRef.isEnd;
    this.cd.detectChanges()
  }

  prepareMatchData() {
    // education
    this.educationLevelRequestsExist = this.match?.education?.ad_values.length > 0;
    this.educationLevelExistInCV = this.match?.education?.cv_current_value;
    this.schoolEducationRequested = this.match?.education?.ad_values.includes("1");
    this.vocationalTrainingRequested = this.match?.education?.ad_values.includes("2");
    this.studiesRequested = this.match?.education?.ad_values.includes("3");
    this.studiesPlusRequested = this.match?.education?.ad_values.includes("4");
    this.educationQualifications = this.match?.education?.cv_education_qualifications;

    // occupations, deep copy.
    this.occupations = JSON.parse(JSON.stringify(this.match))?.occupations;
    this.currentOccupationIndex = 0;
    this.occupationLevelsCanBeCompared = false;

    if (this.occupations) {
      this.occupations.sort((a, b) => new Date(b.last_usage_cv).getFullYear() - new Date(a.last_usage_cv).getFullYear())
        .forEach(occupation => {
          // if this.occupationLevelsCanBeCompared stays false, it will be disabled in the UI
          if (occupation.lvl_ad && occupation.lvl_cv && !this.occupationLevelsCanBeCompared) {
            this.occupationLevelsCanBeCompared = true;
          }
          if (occupation.last_usage_cv) {
            occupation.last_usage_cv = new Date(occupation.last_usage_cv).getFullYear()
          } else {
            occupation.last_usage_cv = this.translate.instant('app_feedback_not_specified');
          }
        });
    }

    this.setOccupationLevels(0);

    // skills
    this.skills = this.match?.skills;
    let languages = this.match?.languages;
    this.showSkillContent = true;

    if ((this.skills?.match?.length == 0) &&
        (this.skills?.addition?.length == 0) &&
        (this.skills?.missing?.length == 0) &&
        (languages.match?.length == 0) &&
        (languages.match?.addition?.length == 0)) {
          this.showSkillContent = false;
    } else {
      if (this.skills?.match?.length > 8)
        this.skills.match = this.skills?.match?.slice(0, 8);
  
      if (this.skills?.addition?.length > 8)
        this.skills.addition = this.skills?.addition?.slice(0, 8);
  
      if (this.skills?.missing?.length > 8)
        this.skills.missing = this.skills?.missing?.slice(0, 8);
    }

    // professional group
    this.showOccupationGroup = true;
    this.mainOccupationGroup = this.match?.professional_group?.main_skill_cv;

    if (!this.mainOccupationGroup?.term || !this.match?.professional_group?.main_skill_ad) {
      this.showOccupationGroup = false;
    } else {
      this.mainOccupationGroup["background"] = "conic-gradient(" + this.gradientColors[this.mainOccupationGroup?.color] + " " +
        this.gradientDegrees[this.mainOccupationGroup?.color] + ", #E3E3E3 " +
        this.gradientDegrees[this.mainOccupationGroup?.color] + ")";
      this.otherOccupationGroups = this.match?.professional_group?.main_skill_list;
      if (this.otherOccupationGroups) {
        this.otherOccupationGroups = this.otherOccupationGroups.sort((a, b) => Number(b.count) - Number(a.count)).
          filter(occupation => {
            if (this.mainOccupationGroup?.term == occupation?.term) {
              return false;
            } else {
              occupation["background"] = "conic-gradient(" + this.gradientColors[occupation.color] + " " +
                                      this.gradientDegrees[occupation?.color] + ", #E3E3E3 " +
                                      this.gradientDegrees[occupation?.color] + ")";
              return true;
            }
          });
        if (this.otherOccupationGroups.length > 4)
          this.otherOccupationGroups = this.otherOccupationGroups.slice(0, 4);
      }
    }
  }

  setOccupationLevels(index: number) {
    this.selectedOccupationCvLevel = this.match?.occupations?.[index]?.lvl_cv;
    this.selectedOccupationAdLevel = this.match?.occupations?.[index]?.lvl_ad;
  }

  toggleFeedbackInfo(id) {
    if (id === this.selectedFeedback)
      this.selectedFeedback = null;
    else
      this.selectedFeedback = id;
  }
}
