import {Injectable, Provider} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ExecutorService} from "../services/executor.service";

// import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class OutboundInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private executor: ExecutorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let headers = {};
    const candidate = this.executor.getCandidateCredentials();
    if (candidate) {
      headers = {'X-Session': candidate?.uuid, 'X-Path': this.router.url};
    }

    if (this.executor.getCandidate())
      headers['X-Localization'] = this.executor.getCandidate().language_code;

    // Workaround for upload
    if (req.method !== 'POST' || (!req.url.endsWith('/api/profiles/details')
      && !req.url.endsWith('/api/medias')
      && !req.url.endsWith('/api/medias'))) {
      // headers['Content-type'] = 'application/json';
    }

    // Remove null or undefined query params
    let params = req.params;
    for (const key of req.params.keys()) {
      if (params.get(key) === undefined || params.get(key) === null) {
        params = params.delete(key, undefined);
      }
    }

    req = req.clone({setHeaders: headers, params: params});
    return next.handle(req);
  }
}

export const outboundInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: OutboundInterceptor,
  multi: true
};
