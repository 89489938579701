import { Component, OnInit } from '@angular/core';
import {PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-start-chat-popover',
  templateUrl: './start-chat-popover.component.html',
  styleUrls: ['./start-chat-popover.component.scss'],
})
export class StartChatPopoverComponent implements OnInit {

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}

  closePopover() {
    this.popoverController.dismiss();
  }
}
