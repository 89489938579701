import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-recruiter-topics',
  templateUrl: './recruiter-topics.component.html',
  styleUrls: ['./recruiter-topics.component.scss'],
})
export class RecruiterTopicsComponent implements OnInit {

  @Output() navigateToHomeScreen = new EventEmitter<string>();
  @Input() recruiterTopics: any;

  constructor() { }

  ngOnInit() {}

  navigateBackToHomeScreen() {
    this.navigateToHomeScreen.emit("FeedbackHomeScreen");
  }

}
