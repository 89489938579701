import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoadingController, ModalController, ToastController} from "@ionic/angular";
import {FormControl} from "@angular/forms";
import {ExecutorService} from "../../services/executor.service";
import {takeUntil} from 'rxjs/operators';
import {AbstractComponent} from "../../components/abstract.component";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss'],
})
export class EditQuestionComponent extends AbstractComponent implements OnInit {

  no;
  messageControl = new FormControl();
  selectedAnswer: string[] = [];
  answeredQuestion: any;

  constructor(private cd: ChangeDetectorRef,
              private modalController: ModalController,
              public executorService: ExecutorService,
              public translate: TranslateService,
              public toastController: ToastController,
              private spinner: NgxSpinnerService,
              private loadingController: LoadingController) {
    super();
  }


  ngOnInit() {
    this.answeredQuestion = this.executorService.getCandidate().answers.find(answer => answer.question.no == this.no);
    this.messageControl.setValue(this.answeredQuestion?.answer);

    if (this.answeredQuestion.question.type === 'BUTTONS') {
      this.selectedAnswer = this.answeredQuestion.answer.split(';');
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }

  isOptionSelected(option, options: any[] = null) {
    return (options || this.selectedAnswer).indexOf(option) !== -1;
  }

  selectOption(answer, multiselect) {
    if (!multiselect) {
      this.selectedAnswer = [answer];
    } else {
      if (this.selectedAnswer.indexOf(answer) !== -1)
        this.selectedAnswer = this.selectedAnswer.filter(item => item != answer);
      else
        this.selectedAnswer.push(answer);
    }
  }


  async confirmAnswer(question) {
    if (this.selectedAnswer?.length == 0 && this.messageControl.value === '') {
      await this.message(this.translate.instant('app_pls_select_question'));
      return;
    }

    let answer = null;
    let answerObject = null;
    switch (question.type) {
      case 'PHONE':
        answerObject = this.messageControl?.value;
        answer = this.messageControl?.value?.internationalNumber;
        break;
      case 'DATE':
      case 'TIME':
        answer = this.messageControl.value;
        break;
      case 'BUTTONS':
        answer = this.selectedAnswer.join(';');
        break;
      default:
        answer = this.messageControl.value;
    }

    //
    const loader = await this.loadingController.create();
    await this.spinner.show();
    this.executorService.editAnswer(question.id, answer, this.answeredQuestion.id, answerObject)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(data => {
        this.selectedAnswer = [];
        this.messageControl.setValue(null);

        this.closeModal();
        this.spinner.hide();
      }, error => {
        this.message(this.translate.instant('app_pls_select_question'));
        this.spinner.hide();
      });
    return false;
  }


  private async message(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000
    });
    toast.present();
  }


}
