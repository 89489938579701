import {Component, OnInit} from '@angular/core';
import {AbstractComponent} from "../../components/abstract.component";
import {ModalController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {ExecutorService} from '../../services/executor.service';
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent extends AbstractComponent implements OnInit {

  selectedScreen: string;
  matchProgressBar: any;
  matchValueContainer: any;
  cvProgressBar: any;
  cvValueContainer: any;

  matchProgressValue: number = 0;
  matchProgressEndValue: number;
  matchProgressColor: string;
  matchProgressText: string
  cvMatchText: string;

  cvProgressValue: number = 0;
  cvProgressEndValue: number;
  cvProgressColor: string;

  feedbackData: any;

  gradientColors: any;

  constructor(private modalController: ModalController,
              public translate: TranslateService,
              public executor: ExecutorService,
              private spinner: NgxSpinnerService) {
    super();
  }

  ngOnInit() {
    this.selectedScreen = "FeedbackHomeScreen";
    this.gradientColors = {red: "#ff5432", orange: "#FCAD41", green: "#23C915"};
    this.processFeedbackData();
    this.setProgressBarStyleAndAnimation();

    console.log(this.feedbackData)
  }


  processFeedbackData() {
    /* matchProgressEndValue is calculated value from CORE
    this.matchProgressEndValue = this.feedbackData?.overall?.match?.value ?
      this.feedbackData?.overall?.match?.value?.toFixed() :
      0; */
      this.matchProgressColor = this.feedbackData?.overall?.match?.color;

      if (this.matchProgressColor == "red") {
        this.matchProgressEndValue = 14;
      } else if (this.matchProgressColor == "orange") {
        this.matchProgressEndValue = 60;
      } else if (this.matchProgressColor == "green") {
        this.matchProgressEndValue = 100;
      }

    this.cvProgressEndValue = this.feedbackData?.overall?.cv_analysis?.value ?
      this.feedbackData?.overall?.cv_analysis?.value?.toFixed() :
      0;
    this.cvProgressColor = this.feedbackData?.overall?.cv_analysis?.color;
    this.generateCvText();
    this.generateMatchProgrestext();
  }

  generateCvText() {
    if (this.feedbackData?.overall?.cv_analysis.color == 'orange') {
      this.cvMatchText = this.translate.instant('app_feedback_yellow_text');
    } else if (this.feedbackData?.overall?.cv_analysis.color == 'red') {
      this.cvMatchText = this.translate.instant('app_feedback_red_text');
    } else if (this.feedbackData?.overall?.cv_analysis.color == 'green') {
      this.cvMatchText = this.translate.instant('app_feedback_green_text');
    }
    return;
  }

  generateMatchProgrestext() {
    if (this.feedbackData?.overall?.match.color == 'orange') {
      this.matchProgressText = this.translate.instant('app_feedback_yellow_text');
    } else if (this.feedbackData?.overall?.match.color == 'red') {
      this.matchProgressText = this.translate.instant('app_feedback_red_text');
    } else if (this.feedbackData?.overall?.match.color == 'green') {
      this.matchProgressText = this.translate.instant('app_feedback_green_text');
    }
    return;
  }

  setProgressBarStyleAndAnimation() {
    this.matchProgressBar = document.querySelector<HTMLElement>(".match-circular-progress");
    this.matchValueContainer = document.querySelector<HTMLElement>(".match-value-container");
    this.cvProgressBar = document.querySelector<HTMLElement>(".cv-circular-progress");
    this.cvValueContainer = document.querySelector<HTMLElement>(".cv-value-container");

    let speed = 7;

    // TODO: Set progress colors dynamically (e.g. via dict)

    if (this.matchProgressEndValue !== 0) {
      let matchProgress = setInterval(() => {
        this.matchProgressValue++
        this.matchValueContainer.textContent = `${this.matchProgressText}`;
        this.matchProgressBar.style.background = `conic-gradient(
          ${this.gradientColors[this.matchProgressColor]} ${this.matchProgressValue * 3.6}deg,
          #E3E3E3 ${this.matchProgressValue}deg
        )`;
        if (this.matchProgressValue == this.matchProgressEndValue) {
          clearInterval(matchProgress);
        }
      }, speed);
    }

    if (this.cvProgressEndValue !== 0) {
      const cvProgress = setInterval(() => {
        this.cvProgressValue++
        this.cvValueContainer.textContent = `${this.cvMatchText}`;
        this.cvProgressBar.style.background = `conic-gradient(
          ${this.gradientColors[this.cvProgressColor]} ${this.cvProgressValue * 3.6}deg,
          #E3E3E3 ${this.cvProgressValue}deg
        )`;
        if (this.cvProgressValue == this.cvProgressEndValue) {
          clearInterval(cvProgress);
        }
      }, speed);
    }
  }

  navigateToHomeScreen(event) {
    this.selectedScreen = event;
  }

  navigateTo(component) {
    this.selectedScreen = component;
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
