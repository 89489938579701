import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatchComponent } from './match/match.component';
import { FeedbackComponent } from './feedback.component';
import { CvComponent } from './cv/cv.component';
import { JobQuestionsComponent } from './job-questions/job-questions.component';
import { RecruiterTopicsComponent } from './recruiter-topics/recruiter-topics.component';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';


@NgModule({
  declarations: [
    FeedbackComponent,
    MatchComponent,
    CvComponent,
    JobQuestionsComponent,
    RecruiterTopicsComponent
  ],
  imports: [
    IonicModule.forRoot(),
    CommonModule,
    TranslateModule,
    SwiperModule
  ],
  bootstrap: [FeedbackComponent]
})
export class FeedbackModule { }
