import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {BrowserModule} from '@angular/platform-browser';
import {TransferHttpCacheModule} from '@nguniversal/common';

import {IonicModule} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {LanguageModalComponent} from './@LogOn/modals/language-modal/language-modal.component';
import {UploadModalComponent} from './@LogOn/modals/upload-modal/upload-modal.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {outboundInterceptorProvider} from './@LogOn/interceptors/outbound.interceptor';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {GdprModalComponent} from "./@LogOn/modals/gdpr-modal/gdpr-modal.component";
import {ContactDetailsComponent} from "./@LogOn/modals/contact-details/contact-details.component";
import {IonIntlTelInputModule} from "ion-intl-tel-input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EditQuestionComponent} from "./@LogOn/modals/edit-question/edit-question.component";
import {CurrencyMaskInputMode, NgxCurrencyModule} from "ngx-currency";
import {DocViewerComponent} from "./@LogOn/modals/doc-viewer/doc-viewer.component";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {NgxSpinnerModule} from "ngx-spinner";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {StartChatPopoverComponent} from "./@LogOn/modals/start-chat-popover/start-chat-popover.component";
import {ImpressumModalComponent} from "./@LogOn/modals/impressum-modal/impressum-modal.component";
import {IframeModalComponent} from "./@LogOn/modals/iframe-modal/iframe-modal.component";
import {PipesModule} from "./@LogOn/pipes/pipes.module";
import {CookiesModalComponent} from "./@LogOn/modals/cookies-modal/cookies-modal.component";
import { FeedbackModule } from './@LogOn/modals/feedback/feedback.module';

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 0,
  prefix: "",
  suffix: "€",
  thousands: ".",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

function getParameterByName(name) {
  // var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  // return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  return 'kotekot';
}
export const TAG_MANAGER_CONFIGS = {
  ggl1: "GTM-WXNZQNP",
  wndk1: "GTM-56XCTQP"
};

let googleTagManagerId = '';
for(const i in TAG_MANAGER_CONFIGS){
  if(getParameterByName('cmpId') == i)
    googleTagManagerId = TAG_MANAGER_CONFIGS[i];
}

@NgModule({
  declarations: [
    AppComponent,
    LanguageModalComponent,
    UploadModalComponent,
    GdprModalComponent,
    CookiesModalComponent,
    ContactDetailsComponent,
    EditQuestionComponent,
    DocViewerComponent,
    StartChatPopoverComponent,
    ImpressumModalComponent,
    IframeModalComponent,
  ],
  entryComponents: [],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
    }),

    IonIntlTelInputModule,
    ReactiveFormsModule,
    NgxDocViewerModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    FeedbackModule,

  ],
  providers: [
    outboundInterceptorProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  //return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  return new TranslateHttpLoader(http, `${environment.api_url}/translations/ui?language=`, '&group=app,general,packages,benefits');
}
