import {Component, OnInit, ViewChild} from '@angular/core';
import {LoadingController, ModalController, IonSelect} from '@ionic/angular';
import {ExecutorService} from "../../services/executor.service";
import {takeUntil} from "rxjs/operators";
import {AbstractComponent} from "../../components/abstract.component";
import {TranslateService} from "@ngx-translate/core";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.component.html',
  styleUrls: ['./language-modal.component.scss'],
})
export class LanguageModalComponent extends AbstractComponent implements OnInit {
  availableLanguages = [
    {
      code: 'de',
      name: 'German'
    },
    {
      code: 'de-informal',
      name: 'German Informal'
    },
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'cs',
      name: 'Czech'
    },
    {
      code: 'fr',
      name: 'French'
    },
    {
      code: 'es',
      name: 'Spanish'
    },
    {
      code: 'pl',
      name: 'Polish'
    },
    {
      code: 'it',
      name: 'Italian'
    },
    {
      code: 'uk',
      name: 'Ukrainian'
    }
  ]
  @ViewChild('languageSelection', { static: false }) languageSelection: IonSelect;
  languages;
  selectedLanguage;

  constructor(private modalController: ModalController,
              public executor: ExecutorService,
              private loadingController: LoadingController,
              private spinner: NgxSpinnerService) {
    super();
  }

  ngOnInit() {
    const jobLanguages = this.executor.getCandidate().job.languages.map(item => item.language_code);
    this.languages = this.availableLanguages.filter(language => jobLanguages.indexOf(language.code) !== -1);
    this.setSelectedLanguage();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  setSelectedLanguage(code = null) {
    this.selectedLanguage = this.availableLanguages.find(language => language.code == (code || this.executor.getLanguage()));
  }

  languageChanged(event) {
    this.setSelectedLanguage(event.target.value);
    this.confirmLanguageChange();
  }

  openLanguageSelection() {
    this.languageSelection.open();
  }

  async confirmLanguageChange() {
    const loader = await this.loadingController.create();
    await this.spinner.show();

    this.executor.changeLanguage(this.selectedLanguage.code)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(data => {
        this.closeModal();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
      });
  }
}
