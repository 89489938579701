import {Component, Input, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {ExecutorService} from "../../services/executor.service";
import {takeUntil} from "rxjs/operators";
import {AbstractComponent} from "../../components/abstract.component";

@Component({
  selector: 'app-language-modal',
  templateUrl: './gdpr-modal.component.html',
  styleUrls: ['./gdpr-modal.component.scss'],
})
export class GdprModalComponent extends AbstractComponent implements OnInit {
  @Input() text: 'GDPR' | 'Impressum' = 'GDPR';

  constructor(private modalController: ModalController,
              public executorService: ExecutorService) {
    super();
  }

  ngOnInit() {

  }

  closeModal() {
    this.modalController.dismiss();
  }
}
