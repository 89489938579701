import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
//import { Plugins, CameraResultType, CameraSource } from '@capacitor/core';
import {ActionSheetController, LoadingController, ModalController, Platform} from '@ionic/angular';
import {ExecutorService} from "../../services/executor.service";
import {map} from "rxjs/operators";
import {NgxSpinnerService} from "ngx-spinner";

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss'],
})
export class UploadModalComponent implements OnInit {
  uploadActionSheet = null;
  uploads$;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  constructor(private modalController: ModalController,
              public executor: ExecutorService,
              private loading: LoadingController,
              private spinner: NgxSpinnerService,
              private plt: Platform,
              private actionSheetCtrl: ActionSheetController) {
  }

  ngOnInit() {
    this.uploads$ = this.executor.candidate$
      .pipe(map(candidate => candidate.uploads));
  }


  async selectImageSource() {
    const buttons = [
      {
        text: 'Take Photo',
        icon: 'camera',
        handler: () => {
    //      this.addImage(CameraSource.Camera);
        }
      },
      {
        text: 'Choose From Photos',
        icon: 'image',
        handler: () => {
  //        this.addImage(CameraSource.Photos);
        }
      }
    ];

    // Only allow file selection inside a browser
    if (!this.plt.is('hybrid')) {
      buttons.push({
        text: 'Choose a File',
        icon: 'attach',
        handler: () => {
          this.fileInput.nativeElement.click();
        }
      });
    }

    this.uploadActionSheet = await this.actionSheetCtrl.create({
      header: 'Select Image Source',
      buttons
    });
    await this.uploadActionSheet.present();
  }

  /*async addImage(source: CameraSource) {
    const image = await Camera.getPhoto({
      quality: 60,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      source
    });

    const blobData = this.b64toBlob(image.base64String, `image/${image.format}`);
    const imageName = 'Give me a name';

    this.uploadService.uploadImage(blobData, imageName, image.format).subscribe((newImage: ApiImage) => {
      this.images.push(newImage);
    });
  }*/


  /*b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }*/

  async uploadFile(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const loading = await this.loading.create();
    await this.spinner.show();

    reader.onload = () => { // note using fat arrow function here if we intend to point at current Class context.
     // this.yourImageDataURL = dataReader.result;
      this.executor.candidateUpload('OTHER', file)
        .subscribe((newImage) => {
        loading.dismiss();
      }, error => this.spinner.hide());
    };
      this.closeModal();
    reader.onerror = (error) => {
      //handle errors
    };
  };

  closeModal() {
    this.modalController.dismiss();
  }
}
