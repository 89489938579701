import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'],
})
export class DocViewerComponent implements OnInit {

  docUrl;
  fileType;

  viewer;

  constructor(private modalController: ModalController) { }

  ngOnInit() {
    switch (this.fileType) {
      case "JPG":
      case "PNG":
      case "PDF":
        return this.viewer = 'url';
      default:
        this.viewer = 'office'
    }
  }

  closeModal() {
    this.modalController.dismiss();
  }
}
