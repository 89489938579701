import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {HyphenatePipe} from "./hyphenate.pipe";
import {SafePipe} from "./safe.pipe";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    HyphenatePipe,
    SafePipe
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    HyphenatePipe,
    SafePipe
  ]
})
export class PipesModule { }
